export const environment = {
  production: true,
  baseUrl: 'https://stageapi-ecomodule.procredit-group.com',
  appInsights: {
    instrumentationKey: 'bf600325-19f8-4f24-b71d-444fe832dd6b',
  },
  msalConfig: {
    uri: 'https://graph.microsoft.com/v1.0/me',
    authority: 'https://login.microsoftonline.com/',
  },
  languageBarVisibility: false,
  isImportEnabled: true,
  isFileUploadEnabled: true,
  enablePB: false,
  maxNumber: 1_000_000_000_000_000,
};
